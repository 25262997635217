import { Fragment, useState } from "react";
import React from "react";
import customClasses from "./DatePicker.module.css";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import moment from "moment";
import { parseISO, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, number, string } from "yup";
import { TextField, Switch } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import HubLocation from "./HubLocation";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "350px" },
}));

const MechanicUpdate = React.memo((props) => {
  const classes = useStyles();

  const { open, onClose, data, onUpdate } = props;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    onUpdate(values);
  };

  const initialValues = {
    id: data && data.id ? data.id : null,
    firstname: data && data.firstname ? data.firstname : "",
    lastname: data && data.lastname ? data.lastname : "",
    mobile: data && data.mobile ? data.mobile : "",
    garage_id: data && data.garage_id ? data.garage_id : "",
    is_active: data && data.is_active,
    start_date: null,
    end_date: null,
  };

  const validationSchema = object().shape({
    firstname: string().required("First Name is required"),
    lastname: string().required("Last Name is required"),
    mobile: number()
      .typeError("Must be a valid number.")
      .required("Mobile is required"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"UPDATE MECHANIC FORM"}
                  </span>
                </DialogTitle>
                <DialogContent dividers>
                  <Box mb={3}>
                    <span
                      style={{
                        fontWeight: "normal",
                        color: "grey",
                        display: "block",
                      }}
                    >
                      Apply Date Leaves
                    </span>
                    <DatePicker
                      minDate={new Date()}
                      placeholderText="Click to select a date"
                      onChange={(dates) => {
                        let [start, end] = dates;

                        // let start_date = start
                        //   ? new Date(format(start, "yyyy-MM-dd").split("T")[0])
                        //   : null;
                        // let end_date = end
                        //   ? new Date(format(end, "yyyy-MM-dd").split("T")[0])
                        //   : null;

                        setFieldValue(`start_date`, start);
                        setFieldValue(`end_date`, end);
                      }}
                      isClearable
                      startDate={values.start_date}
                      endDate={values.end_date}
                      selectsRange
                      popperClassName={`${customClasses["react-datepicker-popper"]}`}
                      className={`${customClasses["react-datepicker"]} ${customClasses["react-datepicker__current-month"]} ${customClasses["react-datepicker__header"]} ${customClasses["react-datepicker__navigation"]} ${customClasses["react-datepicker__day-name"]} ${customClasses["react-datepicker__day"]}`}
                    />
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FastField
                        label="First Name"
                        component={TextField}
                        name="firstname"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Last Name"
                        component={TextField}
                        name="lastname"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Mobile"
                        component={TextField}
                        name="mobile"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Hub"
                        component={HubLocation}
                        name="garage_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Updating" : "Update"}
                  </Button>
                </DialogActions>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default MechanicUpdate;
