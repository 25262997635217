import React from "react";
const CustomServiceFee2Component = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const formatNumberWithCommasAndPeso = (number) => {
    // If there's no value, return empty string (no peso sign)
    if (!number) return "";
    return `₱${new Intl.NumberFormat().format(number)}`;
  };
  return (
    <React.Fragment>
      <div style={{ textAlign: "right" }}>
        {/* <div>₱</div> */}
        <div>
          <input
            {...props}
            {...field}
            value={field.value ? formatNumberWithCommasAndPeso(field.value) : ""}
            style={{
              outline: 0,
              borderWidth: "0",
              textAlign: "right",
            }}
            onChange={(event) => {
              let value = event.target.value;
              if (value.length === 7) return;
              let numbersOnly = value.replace(/\D/g, "");
              setFieldValue("service_fee", numbersOnly);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default CustomServiceFee2Component;
