import React, { useEffect, useState, Fragment, useRef, useMemo } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import moment from "moment";
import { numberWithCommas } from "../../../utils/accounting";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import customClasses from "./DatePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "./custom.css";
import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Slide,
  TextField as TextFieldMUI,
  Typography,
  Toolbar,
} from "@material-ui/core";
import { DatePicker as CustomReactDatepicker } from "@material-ui/pickers";
import DatePicker from "react-datepicker";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker as ReactDatepicker } from "formik-material-ui-pickers";
import { TextField } from "formik-material-ui";
import { green } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { Formik, Form, Field, FastField } from "formik";
import { PaymentType } from "../../../utils/constant";

import CustomMakeComponent from "./CustomMakeComponent";
import CustomModelComponent from "./CustomModelComponent";
import CustomYearComponent from "./CustomYearComponent";
import CustomPaymentMethodComponent from "./CustomPaymentMethodComponent";
import CustomServiceFee2Component from "./CustomServiceFee2Component";
import CustomTotalComponent from "./CustomTotalComponent";
import CustomBookingRefComponent from "./CustomBookingRefComponent";
import CustomDiscountComponent from "./CustomDiscountComponent";
import CustomServiceLocationComponent from "./CustomServiceLocationComponent";
import CustomProvinceComponent from "./CustomProvinceComponent";
import CustomMunicipalityComponent from "./CustomMunicipalityComponent";
import CustomBarangayComponent from "./CustomBarangayComponent";
import CustomSellerType from "./CustomSellerType";
import AlertDialog from "../AlertDialog";
import PromptDialog from "../../common/PromptDialog";
import SelectPropertyType from "../../common/SelectPropertyType";
import ParkingSpaceComponent from "./ParkingSpaceComponent";
import CustomTransactionTypeComponent from "./CustomTransactionTypeComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";

// helpers
import { carBuyingValidationSchema, MenuProps } from "./helpers";

// Api config
import config from "../../../utils/config";
import InputField from "../../common/InputField";
import SelectPaymentType from "../../common/SelectPaymentType";

const muiTheme = createMuiTheme({
  textAlign: "center !important",
});

function getStyles(name, adsSource, theme) {
  return {
    fontWeight:
      adsSource.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  inputMultiple: {
    width: "100%",
    minWidth: "250px",
    height: "45px",
    border: "1.5px solid #ddd",
    borderRadius: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
  titleText2: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  listItem: {
    listStyleType: "none",
    margin: "0px !important",
  },
  liItem: {
    marginBottom: "3px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "2.5",
    letterSpacing: "1.2px",
  },
  data: {
    fontSize: "14px !important",
    fontWeight: "normal",
  },
  label2: {
    fontWeight: "bold",
    fontSize: "14px !important",
    minWidth: "160px",
    lineHeight: "5.0",
    letterSpacing: "1.2px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  gridCustom2: {
    maxWidth: "7.0% !important",
  },
  gridCustom3: {
    maxWidth: "52.5% !important",
  },
  addressDisabled: {
    minWidth: "499px !important",
  },
  remarks: {
    minWidth: "715px !important",
  },
  asterisk: {
    color: "red !important",
    fontSize: "1.3rem",
    lineHeight: "0 !important",
  },
  asterisk2: {
    position: "relative",
    bottom: "5px",
    left: "2px",
    color: "red !important",
    fontSize: "0.8rem !important",
  },
  disabledRoot: {
    "& .MuiOutlinedInput-input": {
      color: "#000",
    },
  },
  largeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  add: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  inputCustomField: {
    width: "500px !important",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  input: {
    textAlign: "center !important",
  },

  customInput: {
    width: "100% !important",
  },

  root: {
    overflowX: "unset",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const CarBuyingAssistanceDialog = (props) => {
  const { open, isLoading, handleCloseAddBooking, category } = props;

  const [adsSource, setAdsSource] = useState([]);
  const [adsSourceData, setAdsSourceData] = useState([]);

  // ==============Alert State==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // ==============Prompt State==============
  const [prompt, setPrompt] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const [excludedTimes, setExcludedTimes] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [valueAuto, setValueAuto] = React.useState([]);
  const [optionsAuto, setOptionsAuto] = React.useState([]);

  const formRef = useRef();
  const classes = useStyles();
  const classes2 = useStyles2();

  const theme = useTheme();

  // ==============Alert==============
  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
      message: "",
    });
  };

  const options = useMemo(() => {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
  }, [props.token]);

  const fetchAdsSources = async () => {
    const {
      data: { data: adsSources },
    } = await axios.get(`${config.api}/ads-sources?limit=10000`, options);
    setAdsSourceData(adsSources);
  };

  const fetchDataExcludedTimes = (date, serviceLocation) => {
    return new Promise((resolve, reject) => {
      let request = axios.get(
        `${config.api}/schedules/available?start_datetime=${date}&garage_id=${serviceLocation}`,
        options
      );
      resolve(request);
    });
  };

  const fetchDataModel = (brand) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        config.api + "/vehicles/search?make=" + brand,
        options
      );
      resolve(request);
    });
  };

  const fetchDataLiter = (brandModel, fuelType) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/vehicles/search?model=${brandModel}&fuel_type=${fuelType}`,
        options
      );
      resolve(request);
    });
  };

  const fetchFuelTypes = (make, model) => {
    return new Promise((resolve, reject) => {
      let request;
      request = axios.get(
        `${config.api}/vehicles/fuel-types?make=${make}&model=${model}`,
        options
      );
      resolve(request);
    });
  };

  const handleSubmitCustom = (values) => {
    const adsSourceJoined = adsSource.join(",");
    setAdsSource([]);
    const merged = { ...values, ads_source: adsSourceJoined };
    props.handleAddBooking(merged);
    return true;
  };

  let provincesList = useMemo(() => {
    return [];
  }, []);

  let manualListInitial = useMemo(() => {
    return [];
  }, []);

  let packageList = useMemo(() => {
    return [];
  }, []);

  const initialValues = {
    ads_source: [],
    reference_no: "",
    customer_id: "",
    created_at: new Date(),
    service_location: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    customer_type: "New",
    province: "",
    municipality: "",
    barangay: "",
    landmarks: "",
    service_fee: 0.0,
    parking_space: "",
    property_type: "",
    coverAddress: "cover",
    distance_in_km: "",
    subTotal: "",
    total: "",
    make: "",
    model: "",
    year: "",
    plate_number: "",
    transmission: "",
    mileage: "",
    fuel_type: "Gas",
    liter_of_oil: "",
    color: "",
    engine_size: "",
    servicesList: [],
    services: packageList,
    tires: [],
    manualServicesList: manualListInitial,
    manualServices: [],
    parts: [],
    coupons: [],
    discounts: "0.00",
    ap_date: null,
    ap_time: null,
    remarks: "",
    models: [],
    provinces: provincesList,
    municipalities: [],
    barangays: [],
    payment_method: "",
    transaction_type: "",
    payment_reference_no: "",
    payment_amount: 0.0,
    payment_type: "",
    seller_type: "",
    seller_name: "",
  };

  useEffect(() => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    };
    async function fetchData() {
      // ========Services========
      const {
        data: { data },
      } = await axios.get(`${config.api}/packages/25`, options);
      packageList.push({ ...data, quantity: 1 });
      return data;
    }

    const fetchProvinces = async () => {
      const { data } = await axios.get(config.api + "/provinces");
      provincesList.push(data);
      return data;
    };

    fetchData();
    fetchProvinces();
  }, [manualListInitial, props.token, provincesList]);

  useEffect(() => {
    let active = true;

    // if (!loading) {
    //   return undefined;
    // }

    (async () => {
      const response = await axios.get(
        `${config.api}/users/customers`,
        options
      );
      // await sleep(1e3); // For demo purposes.
      const users = response.data.data;

      let customers = [];
      if (active) {
        for (let i in users) {
          customers.push(users[i]);
        }
        setOptionsAuto(customers);
      }
    })();
    fetchAdsSources();

    fetchAdsSources();
    return () => {
      active = false;
    };
  }, [options, open]);

  const filterOptions = (optionsAuto, { inputValue }) => {
    const normalizedInput = inputValue.toLowerCase();
    return optionsAuto.filter(
      (option) =>
        option.customer_id.toLowerCase().includes(normalizedInput) ||
        (option.email &&
          option.email.toLowerCase().includes(normalizedInput)) ||
        (option.name && option.name.toLowerCase().includes(normalizedInput)) ||
        (option.lastname &&
          option.lastname.toLowerCase().includes(normalizedInput))
    );
  };

  const ServiceItems = ({ services }) => {
    if (services.length === 0) return <></>;
    return (
      <Fragment>
        {services.map((p) => (
          <Grid container item key={p.id} style={{ marginLeft: "1.5rem" }}>
            <Grid item xs={6}>
              {p?.name} ({p?.quantity || 1}x)
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "right", borderBottom: "1px solid #000" }}
            >
              {numberWithCommas(p?.price ?? p?.variants?.price)}
            </Grid>
          </Grid>
        ))}
      </Fragment>
    );
  };

  const renderPaymentType = (values) => {
    const paymentTypeValue = PaymentType[values.payment_type] || null;
    return paymentTypeValue ? (
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography
            style={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            {paymentTypeValue}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "right",
            borderBottom: "1px solid #000",
          }}
        >
          {numberWithCommas(values.payment_amount)}
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <Fragment>
      <div>
        <AlertDialog
          open={alert.open}
          typeAlert={alert.typeAlert}
          message={alert.message}
          handleCloseAlert={handleCloseAlert}
        />
        <PromptDialog
          open={prompt.open}
          setOpen={() =>
            setPrompt({ open: !prompt.open, title: "", message: "" })
          }
          title={prompt.title}
          message={prompt.message}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleCloseAddBooking}
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={carBuyingValidationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitCustom}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => (
              <Form autoComplete="off">
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCloseAddBooking}
                      aria-label="close"
                    >
                      <CloseIcon className={classes.button} />
                    </IconButton>
                    <Typography variant="h5" className={classes.title}>
                      Add Booking
                      <ListAltSharpIcon className={classes.icon} />
                    </Typography>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      startIcon={
                        isLoading ? (
                          <CircularProgress size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isLoading ? "Saving" : "Save"}
                    </Button>
                  </Toolbar>
                </AppBar>
                <MuiDialogContent dividers>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Field
                            fullWidth
                            label="Reference Number"
                            name="reference_no"
                            type="text"
                            component={CustomBookingRefComponent}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              readOnly: "readonly",
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MuiPickersUtilsProvider
                            theme={muiTheme}
                            utils={DateFnsUtils}
                          >
                            <FastField
                              fullWidth
                              label="Booking Date"
                              component={ReactDatepicker}
                              format="yyyy-MM-dd"
                              size="small"
                              inputVariant="outlined"
                              name="created_at"
                              variant="inline"
                              autoOk
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            label="Transaction Type"
                            component={CustomTransactionTypeComponent}
                            name="transaction_type"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FastField
                            label="Service Location"
                            component={CustomServiceLocationComponent}
                            name="service_location"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={(evt) => {
                              setFieldValue(
                                "service_location",
                                evt.target.value
                              );
                              setFieldValue(`ap_date`, null);
                              setFieldValue(`ap_time`, null);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        CUSTOMER'S INFORMATION
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={4}>
                          <Autocomplete
                            className={classes2.customInput}
                            size="small"
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            filterOptions={filterOptions}
                            id="controllable-states-demo"
                            options={optionsAuto}
                            renderOption={(option) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {option.customer_id && (
                                  <div style={{ fontWeight: "700" }}>
                                    {option.customer_id}
                                  </div>
                                )}
                                <small>{`${option.name}${
                                  option.lastname ? ` ${option.lastname}` : ""
                                } ${
                                  option.email ? `| ${option.email}` : ""
                                }`}</small>
                              </div>
                            )}
                            getOptionLabel={(option) =>
                              option.customer_id || ""
                            }
                            renderInput={(params) => (
                              <TextFieldMUI
                                fullWidth
                                {...params}
                                label="Customer ID"
                                variant="outlined"
                                autoComplete="off"
                              />
                            )}
                            onChange={async (event, newValue) => {
                              setValueAuto(newValue);

                              const {
                                customer_id,
                                name,
                                address,
                                email,
                                phone,
                                lastname,
                                landmarks,
                                province,
                                municipality,
                                barangay,
                              } = newValue || {};

                              const c_name = name || "";
                              const first_name = name || "";
                              const last_name = lastname || "";

                              setFieldValue(`customer_id`, customer_id);
                              setFieldValue(`c_name`, c_name);
                              setFieldValue(`first_name`, first_name);
                              setFieldValue(`last_name`, last_name);
                              setFieldValue(`email`, email || "");
                              setFieldValue(`phone`, phone || "");

                              if (province && municipality && barangay) {
                                const {
                                  data: { fee_amount },
                                } = await axios.get(
                                  `${config.api}/home-service-fee/${province}/${municipality}/${barangay}`
                                );

                                setFieldValue(`service_fee`, fee_amount || "");
                              }
                              if (address === "") {
                                setFieldValue(`distance_in_km`, "");
                              }
                              // setFieldValue("customer_type", "Retention");
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="First Name"
                            component={TextField}
                            name="first_name"
                            type="text"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              style: {
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="Last Name"
                            component={TextField}
                            name="last_name"
                            type="text"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              style: {
                                textTransform: "capitalize",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="Email"
                            component={TextField}
                            name="email"
                            type="email"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="Contact Number"
                            component={TextField}
                            name="phone"
                            type="phone"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        SELLER'S INFORMATION
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                          <FastField
                            fullWidth
                            label="Type of Seller"
                            component={CustomSellerType}
                            name="seller_type"
                            type="text"
                            variant="outlined"
                            size="small"
                            row
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="Seller's Name"
                            component={TextField}
                            name="seller_name"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        PREFERRED LOCATION
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={4}>
                          <FastField
                            fullWidth
                            label="House #, Street, Village/Subdivision"
                            component={TextField}
                            name="address"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            label="Province"
                            component={CustomProvinceComponent}
                            name="province"
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={async (e) => {
                              setFieldValue("province", e.target.value);
                              setFieldValue("municipality", "");
                              setFieldValue("barangay", "");
                              const { data } = await axios.get(
                                `${config.api}/municipality/${e.target.value}`
                              );

                              setFieldValue("municipalities", data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            label="Municipality/City"
                            component={CustomMunicipalityComponent}
                            name="municipality"
                            type="text"
                            variant="outlined"
                            size="small"
                            disabled={!values.province}
                            onChange={async (e) => {
                              setFieldValue("municipality", e.target.value);
                              setFieldValue("barangay", "");
                              const { data } = await axios.get(
                                `${config.api}/barangay/${e.target.value}`
                              );
                              setFieldValue("barangays", data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            label="Barangay/Zip Code"
                            component={CustomBarangayComponent}
                            name="barangay"
                            type="text"
                            variant="outlined"
                            size="small"
                            disabled={!values.municipality}
                            onChange={async (e) => {
                              setFieldValue("barangay", e.target.value);
                              const {
                                data: { fee_amount },
                              } = await axios.get(
                                `${config.api}/home-service-fee/${values.province}/${values.municipality}/${e.target.value}`
                              );
                              const { data: address } = await axios.get(
                                `${config.api}/addressByName?province=${values.province}&municipality=${values.municipality}&barangay=${e.target.value}`
                              );
                              setFieldValue("service_fee", fee_amount);
                              setFieldValue(
                                "zip_code",
                                address?.municipality?.[0]?.barangay?.[0]
                                  ?.zip_code?.[0]?.zip_code
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <FastField
                            fullWidth
                            name="landmarks"
                            component={TextField}
                            label="Landmark"
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="h6" className={classes.titleText2}>
                        VEHICLE INFO
                      </Typography>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            name="make"
                            label="Make"
                            fullWidth
                            variant="outlined"
                            size="small"
                            component={CustomMakeComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("make", value);
                              setFieldValue("model", "");
                              setFieldValue("liter_of_oil", "");
                              const _models = await fetchDataModel(value);
                              setFieldValue("models", _models.data.data);
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <Field
                            name="model"
                            label="Model"
                            variant="outlined"
                            size="small"
                            component={CustomModelComponent}
                            onChange={async (e) => {
                              const { value } = e.target;
                              setFieldValue("model", value);
                              setFieldValue("liter_of_oil", "");

                              let brandModel = `${values.make} ${value}`;
                              const {
                                data: { data: fuelTypes },
                              } = await fetchFuelTypes(values.make, value);
                              let currentFuelType = "Gas";

                              if (fuelTypes) {
                                const fuelTypeIsAvailable =
                                  fuelTypes.includes(currentFuelType);
                                if (!fuelTypeIsAvailable) {
                                  currentFuelType = fuelTypes[0];
                                  setFieldValue("fuel_type", currentFuelType);
                                }
                              }

                              const _literVal = await fetchDataLiter(
                                brandModel,
                                currentFuelType
                              );
                              let liter_of_oil = _literVal.data.data;

                              if (liter_of_oil) {
                                setFieldValue(
                                  "liter_of_oil",
                                  liter_of_oil.liter_of_oil
                                );
                              } else {
                                setFieldValue("liter_of_oil", "");
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            name="year"
                            label="Year"
                            variant="outlined"
                            size="small"
                            component={CustomYearComponent}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("year", value);
                              console.log("typeof year: ", typeof value);
                              if (value <= 2005) {
                                setFieldValue("discounts", "500");
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="plate_number"
                            label="Plate Number"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="color"
                            label="Color"
                            variant="outlined"
                            size="small"
                            component={TextField}
                            inputProps={{
                              style: {
                                textAlign: "center",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="property_type"
                            label="Property Type"
                            variant="outlined"
                            size="small"
                            component={SelectPropertyType}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.gridCustom}>
                          <FastField
                            fullWidth
                            name="parking_space"
                            label="Parking Space"
                            variant="outlined"
                            size="small"
                            component={ParkingSpaceComponent}
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <Grid container>
                        <Grid item xs={4}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            APPOINTMENT DETAILS
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <MuiPickersUtilsProvider
                            theme={muiTheme}
                            utils={DateFnsUtils}
                          >
                            <Grid container>
                              <FormControl fullWidth>
                                <InputLabel
                                  style={{
                                    position: "absolute",
                                    top: "-40px",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Appointment Date
                                </InputLabel>

                                <CustomReactDatepicker
                                  onChange={async (date) => {
                                    if (!values.service_location) {
                                      setPrompt({
                                        open: true,
                                        title: "",
                                        message:
                                          "Service location is required to select an appointment date",
                                      });
                                      return;
                                    }
                                    let date_format = moment(date)
                                      .utc()
                                      .format("YYYY-MM-DD");
                                    setFieldValue(`ap_date`, date);
                                    setFieldValue(`ap_time`, null);
                                    let data = await fetchDataExcludedTimes(
                                      date_format,
                                      values.service_location
                                    );

                                    let exclude_times = [
                                      new Date(date_format + " 00:00:00"),
                                      new Date(date_format + " 01:00:00"),
                                      new Date(date_format + " 02:00:00"),
                                      new Date(date_format + " 03:00:00"),
                                      new Date(date_format + " 04:00:00"),
                                      new Date(date_format + " 05:00:00"),
                                      new Date(date_format + " 06:00:00"),
                                      new Date(date_format + " 07:00:00"),
                                      new Date(date_format + " 17:00:00"),
                                      new Date(date_format + " 18:00:00"),
                                      new Date(date_format + " 19:00:00"),
                                      new Date(date_format + " 20:00:00"),
                                      new Date(date_format + " 21:00:00"),
                                      new Date(date_format + " 22:00:00"),
                                      new Date(date_format + " 23:00:00"),
                                      new Date(date_format + " 24:00:00"),
                                    ];

                                    let data_result =
                                      data?.data?.data?.length > 0
                                        ? data.data.data
                                        : [];

                                    let newArray = data_result
                                      .filter((x) => x.max_slots === 0)
                                      .map((element) => {
                                        let formated_time = moment(
                                          date_format + " " + element.time
                                        );
                                        return new Date(formated_time);
                                      });
                                    let result = exclude_times.concat(newArray);
                                    setExcludedTimes(result);
                                  }}
                                  error={errors.ap_date && touched.ap_date}
                                  helperText={
                                    errors.ap_date &&
                                    touched.ap_date &&
                                    "Appointment date is required."
                                  }
                                  value={values.ap_date}
                                  format="yyyy-MM-dd"
                                  size="small"
                                  inputVariant="outlined"
                                  name="ap_date"
                                  variant="inline"
                                  autoOk
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          // spacing={3}
                          style={{ position: "relative" }}
                        >
                          <InputLabel
                            style={{
                              position: "absolute",
                              top: "-8px",
                              fontSize: "0.8rem",
                            }}
                          >
                            Appointment Time
                          </InputLabel>
                          <div
                            className="datepicker-container"
                            style={{ width: "100%" }}
                          >
                            <DatePicker
                              fullWidth
                              onChange={(date) =>
                                setFieldValue(`ap_time`, date)
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              showPopperArrow={false}
                              selected={values.ap_time}
                              popperProps={{
                                strategy: "fixed",
                              }}
                              excludeTimes={excludedTimes}
                              strictParsing
                              timeIntervals={60}
                              popperPlacement="top-end"
                              popperClassName={`${customClasses["react-datepicker-popper"]}`}
                              className={`${
                                errors.ap_time && touched.ap_time
                                  ? customClasses["error"]
                                  : ""
                              } ${customClasses["custom__heyy"]} ${
                                customClasses["react-datepicker"]
                              } ${
                                customClasses["react-datepicker__current-month"]
                              } ${customClasses["react-datepicker__header"]} ${
                                customClasses["react-datepicker__navigation"]
                              } ${
                                customClasses["react-datepicker__day-name"]
                              } ${customClasses["react-datepicker__day"]}`}
                            />
                          </div>
                          {errors.ap_time && touched.ap_time ? (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {"Appointment Time is required."}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item xs={4} style={{ position: "relative" }}>
                          <InputLabel
                            id="ads-source-label"
                            style={{
                              position: "absolute",
                              top: "-8px",
                              fontSize: "0.8rem",
                            }}
                          >
                            Ads source
                          </InputLabel>
                          <Select
                            labelId="ads-source-label"
                            label="Ads source"
                            id="ads-source"
                            error={errors.ads_source && touched.ads_source}
                            helperText={
                              errors.ads_source &&
                              touched.ads_source &&
                              errors.ads_source
                            }
                            name="ads_source"
                            multiple
                            value={adsSource}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              // On autofill we get a stringified value.
                              const aSources =
                                typeof value === "string"
                                  ? value.split(",")
                                  : value;

                              setFieldValue("ads_source", aSources);
                              setAdsSource(aSources);
                            }}
                            input={
                              <Input
                                id="select-multiple-ads-source"
                                label="Ads source"
                                classes={{
                                  root: classes.inputMultiple, // class name, e.g. `classes-nesting-root-x`
                                }}
                              />
                            }
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {adsSourceData.map((src) => (
                              <MenuItem
                                key={src.id}
                                value={src.ads_source_name}
                                style={getStyles(
                                  src.ads_source_name,
                                  adsSource,
                                  theme
                                )}
                              >
                                {src.ads_source_name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.ads_source && touched.ads_source ? (
                            <FormHelperText
                              id="component-error-text"
                              style={{ color: "#f44336" }}
                            >
                              {"Ads source is required."}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item xs={8}>
                          <FastField
                            component={TextField}
                            multiline
                            label="Remarks"
                            size="small"
                            name="remarks"
                            variant="outlined"
                            fullWidth
                            rows={2}
                            rowsMax={3}
                          />
                        </Grid>
                      </Grid>
                      <Box my={4}>
                        <Divider />
                      </Box>
                      <Grid container>
                        <Grid item xs={4}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            PAYMENT DETAILS
                          </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={7}>
                          {" "}
                          <Typography
                            variant="h6"
                            className={classes.titleText2}
                          >
                            PAYMENT SUMMARY
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item container xs={4} spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              name="payment_method"
                              label="Mode of Payment"
                              variant="outlined"
                              size="small"
                              component={CustomPaymentMethodComponent}
                            />
                            {errors.payment_method && touched.payment_method ? (
                              <FormHelperText
                                id="component-error-text"
                                style={{ color: "#f44336" }}
                              >
                                {"Mode of Payment is required."}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="payment_type"
                              label="Payment type"
                              variant="outlined"
                              size="small"
                              component={SelectPaymentType}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="payment_amount"
                              label="Amount"
                              variant="outlined"
                              size="small"
                              component={InputField}
                              disabled={!values.payment_type}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FastField
                              fullWidth
                              label="Payment Reference No."
                              component={TextField}
                              name="payment_reference_no"
                              type="text"
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={1}></Grid>
                        <Grid container item xs={5} spacing={1}>
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={12}>
                              <Typography style={{ fontWeight: "bold" }}>
                                Service items
                              </Typography>
                              <ServiceItems services={values.services} />
                            </Grid>

                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{ fontWeight: "bold", color: "green" }}
                                >
                                  Subtotal:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                {numberWithCommas(values.subTotal)}
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography style={{ fontWeight: "bold" }}>
                                  Convenience Fee:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                <Field
                                  name="service_fee"
                                  component={CustomServiceFee2Component}
                                />
                              </Grid>
                            </Grid>
                            {values.payment_type && renderPaymentType(values)}
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  Discount:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                  borderBottom: "1px solid #000",
                                }}
                              >
                                <FastField
                                  name="discounts"
                                  component={CustomDiscountComponent}
                                />
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    color: "green",
                                  }}
                                >
                                  Total Balance:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <Field
                                  name="total"
                                  component={CustomTotalComponent}
                                  token={props.token}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid></Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                </MuiDialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default CarBuyingAssistanceDialog;
