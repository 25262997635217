import moment from "moment";
// Function to format date as "month day, year"
export function formatDate(dateStr) {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

// Other helper functions can be defined here
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

export function formatCustomDate(date) {
  const formattedDate = moment(date).format("MMMM D, YYYY h:mma").toUpperCase();
  return formattedDate;
}
