import React, { Fragment, useRef, useState, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { bookFilterActions } from "../store/book-filter-slice";
import OrderStatus from "../components/booking/orderStatus/OrderStatus";
import PaymentStatus from "../components/booking/paymentStatus/PaymentStatus";
import BookingDatePicker from "../components/booking/bookingDatePicker/BookingDatePicker";
import AppointmentDatePicker from "../components/booking/appointmentDatePicker/AppointmentDatePicker";
import AppointmentTimePicker from "../components/booking/appointmentTimePicker/AppointmentTimePicker";
import TotalResultEntries from "../components/booking/totalResultEntries/TotalResultEntries";
import FilterBookingType from "../components/booking/filterBookingType/FilterBookingType";
import BookingDataTable from "../components/booking/BookingDataTable.js";
import AddBooking from "../components/booking/addBooking/AddBooking";
import AddBookingDialog from "../components/booking/addBooking/AddBookingDialog";
import CarBuyingAssistanceDialog from "../components/booking/addBooking/CarBuyingAssistanceDialog";
import AlertDialog from "../components/alertDialog/AlertDialog";
import { Box, Grid, Typography } from "@material-ui/core";
import SelectServiceLocation from "../components/common/SelectServiceLocation";
import SelectTransactionType from "../components/common/SelectTransactionType";
import ExportAppointment from "../components/booking/export/export";

// utils
import { numberConverter } from "../utils/accounting";
// Api config
import config from "../utils/config";
import SelectSalesAgent from "../components/common/SelectSalesAgent";

const Booking = (props) => {
  const { token } = props;

  const tableRef = useRef();
  const dispatch = useDispatch();

  const serviceLocation = useSelector(
    (state) => state.bookFilter.serviceLocation
  );
  const transactionType = useSelector(
    (state) => state.bookFilter.transactionType
  );

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const [category, setCategory] = useState(null);
  const [addBooking, setAddBooking] = useState({
    open: false,
    isLoading: false,
  });
  const [carBuying, setCarBuying] = useState({
    open: false,
    isLoading: false,
  });

  const [open, setOpen] = useState(false);

  // Add Booking Dialog
  const handleOpenAddBooking = useCallback(
    (category) => {
      if (category && category.id === 2) {
        setCarBuying({ ...carBuying, open: true, isLoading: false });
      } else {
        setAddBooking({ ...addBooking, open: true, isLoading: false });
      }
      setCategory(category);
    },
    [addBooking]
  );

  const handleCloseAddBooking = useCallback(() => {
    setAddBooking({ ...addBooking, open: false, isLoading: false });
    setCarBuying({ ...carBuying, open: false, isLoading: false });
  }, [addBooking, carBuying]);

  const handleAddBooking = useCallback(
    (data) => {
      let date = moment(data.ap_date).format("YYYY-MM-DD");
      let time = moment(data.ap_time).format("HH:mm:ss");
      let appointmentDateval = moment(`${date} ${time}`).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      let combinedServices = data.services; // this is the packages

      let manualServices = data.manualServices; // this is the services

      if (manualServices.length > 0) {
        let transformedManualServices = manualServices.map((item) => {
          return {
            id: item.id,
            name: item.name,
            remarks: item.remarks,
            quantity: item.quantity,
            price: item.price,
            total: item.totalPrice,
            type: "manual",
            description: item.description || null,
            package_id: item.package_id || null,
            saved_amount: item.saved_amount || null,
            is_addOn: item.is_addOn || false,
          };
        });
        combinedServices = [...combinedServices, ...transformedManualServices];
      }

      let values = {
        customer_id: data.customer_id,
        user_id: 0,
        lead_source: "backend",
        service_location: data.service_location,
        ads_source: data.ads_source || null,
        name: data.first_name,
        lastname: data.last_name,
        email: data.email,
        address: data.address,
        customer_type: data.customer_type,
        province: data.province,
        municipality: data.municipality,
        barangay: data.barangay,
        parking_space: data.parking_space,
        property_type: data.property_type,
        zip_code: data.zip_code,
        landmarks: data.landmarks,
        contact_number: data.phone,
        service_fee: numberConverter(data.service_fee),
        distance_in_km: data.distance_in_km || 0,
        subTotal: data.subTotal,
        total: data.total,
        discount: data.discounts ? numberConverter(data.discounts) : 0.0,
        paymentMethod: data.payment_method,
        appointmentDate: appointmentDateval,
        make: data.make,
        model: `${data.make} ${data.model}`,
        year: data.year,
        plate_number: data.plate_number,
        color: data.color,
        transmission: data.transmission,
        fuel_type: data.fuel_type,
        mileage: data.mileage,
        liter_of_oil: data.liter_of_oil,
        engine_size: data.engine_size,
        services: combinedServices,
        parts: data.parts,
        tires: data.tires,
        remarks: data.remarks,
        coupon: data.coupons,
        transaction_type: data.transaction_type,
        payment_amount: data.payment_amount,
        payment_reference_no: data.payment_reference_no,
        payment_type: data.payment_type,
        seller_type: data.seller_type,
        seller_name: data.seller_name,
        odometer: data.odometer,
      };
      // Start loading
      setAddBooking((prev) => ({ ...prev, isLoading: true }));
      setCarBuying((prev) => ({ ...prev, isLoading: true }));
      axios
        .post(`${config.api}/appointment`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Success: Stop loading and show success alert
          setAddBooking((prev) => ({ ...prev, open: false, isLoading: false }));
          setCarBuying((prev) => ({ ...prev, open: false, isLoading: false }));
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Added",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          // Error: Stop loading and show error alert
          setAddBooking((prev) => ({ ...prev, open: false, isLoading: false }));
          setCarBuying((prev) => ({ ...prev, open: false, isLoading: false }));
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [addBooking, carBuying, alert, token]
  );

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const handleLocationChange = (location) => {
    dispatch(
      bookFilterActions.changeServiceLocation({
        serviceLocation: location,
      })
    );
  };

  const handleTransactionChange = (type) => {
    dispatch(
      bookFilterActions.changeTransactionType({
        transactionType: type,
      })
    );
  };

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* Add Booking Dialog */}
      <AddBookingDialog
        token={token}
        isLoading={addBooking.isLoading}
        open={addBooking.open}
        category={category}
        handleCloseAddBooking={handleCloseAddBooking}
        handleAddBooking={handleAddBooking}
      />
      <CarBuyingAssistanceDialog
        token={token}
        isLoading={carBuying.isLoading}
        open={carBuying.open}
        category={category}
        handleCloseAddBooking={handleCloseAddBooking}
        handleAddBooking={handleAddBooking}
      />
      <Box mb={1}>
        <OrderStatus />
      </Box>
      <Box mb={1}>
        <PaymentStatus />
      </Box>
      <Box mb={1}>
        <BookingDatePicker />
        <AppointmentDatePicker />
        <AppointmentTimePicker />
        <Grid
          container
          style={{
            background: "#fff",
            borderLeft: "1px solid #e8e8e8",
            borderRight: "1px solid #e8e8e8",
          }}
        >
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={3}>
              <Box ml={2}>
                <Typography
                  paragraph={true}
                  style={{
                    margin: 0,
                    fontSize: "0.845rem",
                    fontWeight: "bold",
                    opacity: 0.7,
                    color: "#000",
                  }}
                >
                  Service Location:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mr={14}>
                <SelectServiceLocation
                  handleLocationChange={handleLocationChange}
                  serviceLocation={serviceLocation}
                  isMultiple={true}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box ml={2}>
                <Typography
                  paragraph={true}
                  style={{
                    margin: 0,
                    fontSize: "0.845rem",
                    fontWeight: "bold",
                    opacity: 0.7,
                    color: "#000",
                  }}
                >
                  Sales Agent:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mr={14}>
                <Grid>
                  <SelectSalesAgent />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={3}>
              <Box ml={2}>
                <Typography
                  paragraph={true}
                  style={{
                    margin: 0,
                    fontSize: "0.845rem",
                    fontWeight: "bold",
                    opacity: 0.7,
                    color: "#000",
                  }}
                >
                  Transaction Type:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mr={14}>
                <SelectTransactionType
                  handleTransactionChange={handleTransactionChange}
                  transactionType={transactionType}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <TotalResultEntries />
      </Box>
      <Box mb={2} display="flex" gridGap={20} alignItems="center">
        <FilterBookingType />
        <ExportAppointment />
        <AddBooking handleOpenAddBooking={handleOpenAddBooking} />
      </Box>
      <Box mb={1}>
        <BookingDataTable token={token} tableRef={tableRef} />
      </Box>
    </Fragment>
  );
};

export default Booking;
