import * as React from "react";
import axios from "axios";

import { Button, Box } from "@material-ui/core";
import AsyncSelect from "react-select/async";

import FormAddParts from "../../common/FormAddParts";

// Api config
import config from "../../../utils/config";

const CustomPartsAutocompleteComponent = ({ index, values, setFieldValue }) => {
  const [partModalIsOpen, setPartModalIsOpen] = React.useState(false);

  const loadOptions = async (inputValue, callback) => {
    try {
      // Making an Axios GET request to retrieve parts data from goparts endpoint
      const iValue = inputValue ?? "a";
      const response = await axios.get(
        `${config.goparts_api}/parts?q=${inputValue}`
      );

      // Destructuring the response to extract the nested 'data' property
      const {
        data: {
          data: { data: partsData },
        },
      } = response;

      // Create a list of parts based on the API response
      const partsList = partsData.map((item) => {
        return {
          id: item.id,
          part_number: item.part_number,
          remarks: item.remarks,
          name: item.name,
          brand_id: item.brand_id,
          category_id: item.category_id,
          price: 0.0,
          totalPrice: 0,
        };
      });
      return partsList;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddPartOpen = () => {
    setPartModalIsOpen(true);
  };

  const handleAddPartOnClose = () => {
    setPartModalIsOpen(false);
  };

  const handleAddPartSubmit = async (values) => {
    setFieldValue(`parts.${index}`, {
      category_id: values.category_id,
      part_number: values.part_number,
      brand_id: values.brand_id,
      name: values.name,
      id: values.id,
      quantity: 1,
    });
    handleAddPartOnClose();
  };

  return (
    <>
      <FormAddParts
        open={partModalIsOpen}
        onOpen={handleAddPartOpen}
        onClose={handleAddPartOnClose}
        handleAddPartSubmit={handleAddPartSubmit}
      />
      <AsyncSelect
        name={`parts.${index}.name`}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.hover ? "transparent" : "transparent",
            borderBottom: "solid",
            borderStyle: "unset",
            borderRadius: "0",
            boxShadow: "unset",
          }),
        }}
        cacheOptions
        isClearable
        defaultOptions
        value={values?.parts?.[index]?.id ? values.parts[index] : ""}
        placeholder=""
        hideSelectedOptions
        onChange={(newValue) => {
          setFieldValue(`parts.${index}`, {
            ...newValue,
            quantity: 1,
          });
        }}
        loadOptions={loadOptions}
        getOptionLabel={(option) => option.name}
        formatOptionLabel={(option) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {option.name && (
              <div style={{ fontWeight: "700" }}>{option.name}</div>
            )}
            {option.part_number && <small>Part #: {option.part_number}</small>}
          </div>
        )}
        noOptionsMessage={() => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Part not found.
              <Button
                variant="outlined"
                color="primary"
                onMouseDown={() => handleAddPartOpen()}
              >
                Add New Part
              </Button>
            </Box>
          );
        }}
      />
    </>
  );
};

export default CustomPartsAutocompleteComponent;
