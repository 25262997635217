import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { Typography, Grid, Paper, TextField } from '@material-ui/core';
// External
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  error: {
    color: red[500],
  },
  icon: {
    position: 'relative',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
  },
  checkmark: {
    position: 'absolute',
    top: '8px',
    left: '16px',
    width: '8px',
    height: '20px',
    border: 'solid #fff',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)',
  },
}));

const Others = ({ data }) => {
  return (
    <div>
      <Paper variant="outlined" style={{ marginBottom: 20 }}>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Signature</strong>
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <p>Photo:</p>
            {data?.extended_information?.esignature_photo_url && (
              <PhotoProvider>
                <PhotoView
                  src={data?.extended_information?.esignature_photo_url}
                >
                  <img
                    src={data?.extended_information?.esignature_photo_url}
                    alt=""
                    style={{ width: '80px', height: '80px' }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Plate Number</strong>
              <p>{data?.extended_information?.plate_number}</p>
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <p>Photo:</p>
            {data?.extended_information?.plate_number_photo_url && (
              <PhotoProvider>
                <PhotoView
                  src={data?.extended_information?.plate_number_photo_url}
                >
                  <img
                    src={data?.extended_information?.plate_number_photo_url}
                    alt=""
                    style={{ width: '80px', height: '80px' }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Mileage</strong>
              <p>{data?.extended_information?.mileage}</p>
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <p>Photo:</p>
            {data?.extended_information?.mileage_photo_url && (
              <PhotoProvider>
                <PhotoView src={data?.extended_information?.mileage_photo_url}>
                  <img
                    src={data?.extended_information?.mileage_photo_url}
                    alt=""
                    style={{ width: '80px', height: '80px' }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>VIN Number</strong>
              <p>{data?.extended_information?.vin_number}</p>
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <p>Photo:</p>
            {data?.extended_information?.vin_number_photo_url && (
              <PhotoProvider>
                <PhotoView
                  src={data?.extended_information?.vin_number_photo_url}
                >
                  <img
                    src={data?.extended_information?.vin_number_photo_url}
                    alt=""
                    style={{ width: '80px', height: '80px' }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Tire Size</strong>
              <p>{data?.extended_information?.tire_size}</p>
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <p>Photo:</p>
            {data?.extended_information?.tire_size_photo_url && (
              <PhotoProvider>
                <PhotoView
                  src={data?.extended_information?.tire_size_photo_url}
                >
                  <img
                    src={data?.extended_information?.tire_size_photo_url}
                    alt=""
                    style={{ width: '80px', height: '80px' }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Mechanic Remarks</strong>
              <p>{data?.extended_information?.remarks}</p>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Additional Needed Services</strong>
              <p>{data?.extended_information?.needed_services}</p>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>Start Time</strong>
              {data?.extended_information?.start_time_at && (
                <p>
                  {moment
                    .unix(data?.extended_information?.start_time_at)
                    .format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </p>
              )}
            </Typography>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: 10 }}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 10, paddingTop: 15 }}
            >
              <strong>End Time</strong>
              {data?.extended_information?.end_time_at && (
                <p>
                  {moment
                    .unix(data?.extended_information?.end_time_at)
                    .format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </p>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Others;
