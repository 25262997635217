import React, { Fragment, useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from '../booking/TableIcons';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow, orange, red, purple } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from '../alertDialog/AlertDialog';
import { useSelector, useDispatch } from 'react-redux';
import { bookFilterActions } from '../../store/book-filter-slice';
import useModal from '../../hooks/use-modal';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// Components
import RemarksDialog from './RemarksDialog';

// Api config
import config from '../../utils/config';
import { TextField, colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  update: {
    '&:hover': {
      backgroundColor: 'rgba(255, 253, 231,1.0)',
    },
    cursor: 'pointer',
  },
  ellipsis: {
    width: '200px',
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-block',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const AssignMechanicTable = React.memo((props) => {
  const { tableRef, token } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const bookFilter = useSelector((state) => state.bookFilter);
  const dispatch = useDispatch();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    typeAlert: 'success',
  });

  // Remarks Dialog
  const {
    modalState: modalStateRemarks,
    modalData: modalDataRemarks,
    openHandler: modalOpenRemarks,
    closeHandler: modalCloseRemarks,
  } = useModal();

  const [mechanics, setMechanics] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/mechanics/all`, options);
      setMechanics(data);
    }
    fetchData();
    dispatch(
      bookFilterActions.changeFilterBookingType({ filterBookingType: 2 })
    );
  }, []);

  React.useEffect(() => {
    if (bookFilter && bookFilter.search) {
      tableRef.current.onChangePage({}, 0);
    }
    return () => {};
  }, [tableRef, bookFilter]);

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: '',
      });
    },
    [alert]
  );

  const handleChange = async (mechanicId, apptId) => {
    setIsLoading(true);
    axios
      .patch(
        `${config.api}/appointments/${apptId}`,
        { mechanic_id: mechanicId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: 'Successfully Added Mechanic',
          typeAlert: 'success',
        });
        tableRef.current && tableRef.current.onQueryChange();
      })
      .catch((error) => {
        setIsLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: 'Something went wrong please try again.',
          typeAlert: 'error',
        });
      });
    setIsLoading(false);
  };

  const handleAssignUpdate = async (apptId, mechanics, status = 5) => {
    setIsLoading(true);
    try {
      const values = {
        id: apptId,
        status,
        mechanics,
      };
      await axios.patch(`${config.api}/appointments/${apptId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      tableRef.current && tableRef.current.onQueryChange();
      setAlert({
        ...alert,
        open: true,
        message: 'Successfully deleted mechanic',
        typeAlert: 'success',
      });
    } catch (error) {
      setAlert({
        ...alert,
        open: true,
        message: 'Something went wrong please try again.',
        typeAlert: 'error',
      });
      console.log('error');
    }
    setIsLoading(false);
  };

  const handleDeleteMechanic = (client, mechanic) => {
    const data = client.appointment_mechanics.filter(
      (item) => item.id !== mechanic.id
    );
    handleAssignUpdate(client.id, data);
  };

  const handleRemarks = async (values) => {
    try {
      const data = {
        mechanic_appointment_remarks: values?.mechanic_appointment_remarks,
      };
      await axios.patch(`${config.api}/appointments/${values?.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      tableRef.current && tableRef.current.onQueryChange();
      modalCloseRemarks();
      setAlert({
        ...alert,
        open: true,
        message: 'Successfully remarks was added',
        typeAlert: 'success',
      });
    } catch (error) {
      setAlert({
        ...alert,
        open: true,
        message: 'Something went wrong please try again.',
        typeAlert: 'error',
      });
    }
  };

  const onRequestMechanicSuggestion = async (rowData) => {
    handleMechanicSuggestion(rowData);
  };

  const handleMechanicSuggestion = async (rowData) => {
    try {
      setIsLoading(true);
      await axios.get(
        `${config.api}/appointments/${rowData?.id}/mechanic/suggestion`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      tableRef.current && tableRef.current.onQueryChange();
      setAlert({
        ...alert,
        open: true,
        message: 'Successfully updated',
        typeAlert: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      setAlert({
        ...alert,
        open: true,
        message: 'Something went wrong please try again.',
        typeAlert: 'error',
      });
    }
  };

  return (
    <>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================Remarks Dialog - (MODAL)================= */}
      <RemarksDialog
        token={token}
        data={modalDataRemarks}
        isLoading={isLoading}
        open={modalStateRemarks}
        onUpdate={handleRemarks}
        onClose={modalCloseRemarks}
      />
      <MaterialTable
        icons={tableIcons}
        title={`Assign Mechanic Table`}
        tableRef={tableRef}
        isLoading={isLoading}
        columns={[
          {
            title: 'Appointment Date & Time',
            field: 'appointment_date',
            render: (client) => {
              return moment(client.appointment_date).format(
                'YYYY-MM-DD hh:mm A'
              );
            },
          },
          {
            title: 'Vehicle',
            field: 'model',
            render: (client) => {
              return `${client.make} ${client.model} ${client.year} ${client.transmission} `;
            },
          },
          {
            title: 'Customer Name',
            field: 'name',
            render: (data) => {
              let name;
              typeof data.name !== 'string'
                ? (name = '')
                : (name = data.name + ' ' + data.lastname);
              if (data?.transaction_type) {
                name = `${name} / ${data.transaction_type}`;
              }
              return (
                <span style={{ textTransform: 'capitalize' }}>{name}</span>
              );
            },
          },
          {
            title: 'Location',
            field: 'service_location',
          },
          {
            title: 'Address',
            field: 'address',
            // sorting: false,
            render: (data) => {
              // console.log("data address: ", data);
              const address = `${data.address}, ${data.barangay}, ${data.municipality}, ${data.province}, ${data.zip_code}`;
              let hasAddress = false;
              if (data.barangay && data.municipality && data.province) {
                hasAddress = true;
              }
              return <>{hasAddress ? address : data.address}</>;
            },
          },
          {
            title: 'City',
            field: 'municipality',
          },
          {
            title: 'Service Name',
            sorting: false,
            render: (data) => {
              return (
                <div>
                  {data.appointment_services.map((item, index) => (
                    <Tooltip title={item.service_name} key={index}>
                      <div
                        style={{
                          display: 'inline-block',
                          maxWidth: '150px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.service_name.length > 50
                          ? item.service_name.substring(0, 50) + '...'
                          : item.service_name}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              );
            },
          },
          {
            title: 'Appointment Remarks',
            field: 'remarks',
            render: (rowData) => {
              const truncatedRemarks =
                rowData?.remarks?.length > 30
                  ? rowData.remarks.substring(0, 30) + '...'
                  : rowData.remarks;

              return (
                <Tooltip title={rowData.remarks}>
                  <span>{truncatedRemarks}</span>
                </Tooltip>
              );
            },
          },
          {
            title: 'Mechanic/s',
            // sorting: false,
            field: 'name',
            render: (client) => {
              let mechanics = client.appointment_mechanics.map((item) => {
                return (
                  <Chip
                    style={{ marginBottom: '0.2rem' }}
                    key={item.id}
                    onDelete={() => handleDeleteMechanic(client, item)}
                    label={`${item.mechanic.firstname} ${item.mechanic.lastname}`}
                    color="primary"
                  />
                );
              });
              return <>{mechanics}</>;
            },
          },
          {
            title: 'Assignment Remarks',
            field: 'mechanic_appointment_remarks',
            render: (rowData) => {
              if (!rowData?.mechanic_appointment_remarks) {
                return (
                  <Tooltip title="Remarks">
                    <IconButton
                      aria-label="remarks"
                      className={classes.update}
                      size="small"
                      onClick={(event) => modalOpenRemarks(rowData)}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                );
              }
              return (
                <>
                  <p>
                    {rowData?.mechanic_appointment_remarks}
                    <Tooltip title="Remarks">
                      <IconButton
                        aria-label="remarks"
                        className={classes.update}
                        size="small"
                        onClick={(event) => modalOpenRemarks(rowData)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </p>
                </>
              );
            },
          },
          {
            title: 'Hub Suggestion',
            render: (rowData) => {
              if (!rowData.mechanic_assignment?.hub) {
                return (
                  <Tooltip title="Remarks">
                    <IconButton
                      aria-label="remarks"
                      className={classes.update}
                      size="small"
                      onClick={(event) => onRequestMechanicSuggestion(rowData)}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                );
              }
              return rowData?.mechanic_assignment?.hub;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + '/appointments';
            url += `?page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.appointmentDateFrom &&
              bookFilter.appointmentDateTo
            ) {
              url += `&appointment_date_from=${bookFilter.appointmentDateFrom}&appointment_date_to=${bookFilter.appointmentDateTo}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.serviceLocation.length
            ) {
              url += `&service_location=${bookFilter.serviceLocation}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.transactionType.length
            ) {
              url += `&transaction_type=${bookFilter.transactionType}`;
            }
            if (query.orderBy && query.orderDirection) {
              url += `&sort=${query.orderDirection}&field=${query.orderBy.field}`;
            }
            url += '&appointment_status=2,3,4,5';
            const options = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: 'assign',
            tooltip: 'Assign',
            onClick: (event, rowData) => {},
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Assign=====================
            const { action, data } = props;

            const mechanicIds = data?.appointment_mechanics.map(
              (item) => item.mechanic_id
            );
            const filteredMechanics = mechanics.filter(
              (item) => !mechanicIds.includes(item.id)
            );

            if (action?.icon === 'assign') {
              return (
                <div>
                  <Autocomplete
                    id="select-mechanic"
                    options={filteredMechanics}
                    getOptionLabel={(option) =>
                      `${option.firstname} ${option.lastname}`
                    }
                    renderOption={(option) => (
                      <>
                        {option.lastname} {option.firstname}
                      </>
                    )}
                    onChange={(evt, value) => handleChange(value.id, data.id)}
                    style={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a mechanic"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: 'bold',
          },
          rowStyle: {
            fontSize: '.75rem',
            padding: '0px !important',
          },
          actionsCellStyle: {
            justifyContent: 'center',
            padding: '24px',
            marginBottom: '-1px',
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: 'auto',
          sorting: true,
        }}
      />
    </>
  );
});

export default AssignMechanicTable;
