// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DatePicker_react-datepicker-popper__27LLk {\n  z-index: 10000 !important;\n}\n\n.DatePicker_react-datepicker__1ZEAN {\n  font-size: 1rem !important;\n  width: 300px !important;\n}\n\n.DatePicker_react-datepicker__current-month__3BB02 {\n  font-size: 20px !important;\n  text-align: center;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.DatePicker_react-datepicker__header__1hL-2 {\n  padding-top: 6px !important;\n}\n\n.DatePicker_react-datepicker__navigation__m_Cgq {\n  top: 13px !important;\n}\n\n.DatePicker_react-datepicker__day-name__V4sB3,\n.DatePicker_react-datepicker__day__2zD17 {\n  /* margin: 0.5rem !important; */\n  \n}\n", "",{"version":3,"sources":["webpack://src/components/mechanic/DatePicker.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;;EAEE,+BAA+B;;AAEjC","sourcesContent":[".react-datepicker-popper {\n  z-index: 10000 !important;\n}\n\n.react-datepicker {\n  font-size: 1rem !important;\n  width: 300px !important;\n}\n\n.react-datepicker__current-month {\n  font-size: 20px !important;\n  text-align: center;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.react-datepicker__header {\n  padding-top: 6px !important;\n}\n\n.react-datepicker__navigation {\n  top: 13px !important;\n}\n\n.react-datepicker__day-name,\n.react-datepicker__day {\n  /* margin: 0.5rem !important; */\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"react-datepicker-popper": "DatePicker_react-datepicker-popper__27LLk",
	"react-datepicker": "DatePicker_react-datepicker__1ZEAN",
	"react-datepicker__current-month": "DatePicker_react-datepicker__current-month__3BB02",
	"react-datepicker__header": "DatePicker_react-datepicker__header__1hL-2",
	"react-datepicker__navigation": "DatePicker_react-datepicker__navigation__m_Cgq",
	"react-datepicker__day-name": "DatePicker_react-datepicker__day-name__V4sB3",
	"react-datepicker__day": "DatePicker_react-datepicker__day__2zD17"
};
export default ___CSS_LOADER_EXPORT___;
