import React, { useEffect, useMemo } from "react";

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const strToNumberConverter = (number) => {
  if (Number.isInteger(number)) return number;
  return number.replace(/,/g, "");
};

const CustomTotalComponentUpdate = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const memoizedValues = useMemo(() => values, [values]);

  useEffect(() => {
    const {
      services,
      manualServices,
      service_fee: initialServiceFee,
      discounts,
      parts,
      tires,
      payment_amount,
    } = memoizedValues;
    const serviceFee = initialServiceFee
      ? strToNumberConverter(initialServiceFee)
      : 0.0;

    // Calculate subtotal for packages
    const subTotalNM = services.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) * item.quantity;
      return itemPrice + acc;
    }, 0);

    // Calculate total price for services
    const manualServicesTotalPrice = manualServices.reduce(
      (acc, item) => acc + parseFloat(item.totalPrice),
      0
    );

    // Calculate total price for parts
    const partsTotalPrice = parts.reduce(
      (acc, item) => acc + parseFloat(item.totalPrice),
      0
    );

    // Calculate total price for tires
    const tiresTotalPrice = tires.reduce(
      (acc, item) => acc + parseFloat(item.totalPrice),
      0
    );

    // Parse total discount
    const discount = values?.discounts
      ? strToNumberConverter(values.discounts)
      : 0.0;

    const paymentAmount = parseFloat(payment_amount) || 0;

    const subTotal =
      parseFloat(subTotalNM) +
      partsTotalPrice +
      manualServicesTotalPrice +
      tiresTotalPrice;
    const total =
      parseFloat(serviceFee) + subTotal - parseFloat(discount) - paymentAmount;

    // Ensure the final total is not negative
    const totalFinal = Math.max(0, total);

    setFieldValue("subTotal", subTotal);
    setFieldValue("total", totalFinal);
  }, [
    values.services,
    values.manualServices,
    values.parts,
    values.service_fee,
    values.discounts,
    values.tires,
    values.payment_amount,
  ]);

  return (
    <React.Fragment>
      <input {...props} {...field} style={{ display: "none" }} />
      <span>{field.value ? numberConverter(+field.value) : "0.00"}</span>
    </React.Fragment>
  );
};
export default CustomTotalComponentUpdate;
