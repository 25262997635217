import * as React from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

// Api config
import config from '../../../utils/config';

const CustomTiresAutocompleteComponent = ({
  index,
  values,
  setFieldValue,
}) => {

  const loadOptions = async (inputValue) => {
    try {
      // Making an Axios GET request to retrieve data from gulong
      const { data } = await axios.get(`${config.gulong_api}/product_search?q=${inputValue}`);

      // Create a list of parts based on the API response
      const tires = data.map((item) => {
        return {
          sku: item.model,
          quantity: 1,
          price: 0.0,
          totalPrice: 0,
        };
      });
      return tires
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  

  return (
    <AsyncSelect
      name={`tires.${index}.name`}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.hover ? 'transparent' : 'transparent',
          borderBottom: 'solid',
          borderStyle: 'unset',
          borderRadius: '0',
          boxShadow: 'unset'
        }),
      }}
      cacheOptions
      isClearable
      defaultOptions
      value={values?.tires?.[index]?.sku ? values.tires[index] : ''}
      placeholder=''
      hideSelectedOptions
      onChange={(newValue) => {
        setFieldValue(`tires.${index}`, {
          ...newValue,
          quantity: 1,
        });
      }}
      loadOptions={loadOptions}
      getOptionLabel={(option) => option.sku}
      formatOptionLabel={(option) => (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <div style={{ fontWeight: '700' }}>{option.sku}</div>
        </div>
      )}
    />
  );
};
export default CustomTiresAutocompleteComponent;
