import { Fragment, useRef } from "react";
// mui
import { Box } from '@material-ui/core';

const MechanicAttendance = (props) => {
  const { token } = props;
  const tableRef = useRef();

  return (
    <Fragment>
        <Box mb={1}>
           <p>Mechanics Attendances</p>
        </Box>
    </Fragment>
  )
}

export default MechanicAttendance;