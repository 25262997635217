import React, { Fragment } from "react";
import moment from "moment";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
    IconButton,
    Typography,
    Slide,
    Toolbar,
    AppBar,
    Divider,
    Dialog,
    Box,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import Map from "./Map";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    paper: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(4),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    button: {
        color: theme.palette.grey[900],
    },
    icon: {
        position: "absolute",
        top: "18px",
        marginLeft: "3px",
    },
    titleText: {
        fontWeight: "bold",
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        fontSize: "14px !important",
    },
    boxItem: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    listItem: {
        listStyleType: "none",
    },
    liItem: {
        marginBottom: "3px",
    },
    label: {
        fontWeight: "bold",
        fontSize: "14px !important",
        minWidth: "170px",
    },
    data: {
        fontSize: "14px !important",
        fontWeight: "normal",
    },
    liItem2: {
        marginBottom: "3px",
        lineHeight: "1.2",
    },
    label2: {
        fontWeight: "800",
        fontSize: "16px !important",
        minWidth: "25%",
        textAlign: "right",
        marginRight: "160px",
    },
    data2: {
        fontSize: "16px !important",
        fontWeight: "normal",
        minWidth: "45%",
        textAlign: "left",
    },
    appBar: {
        position: "relative",
        backgroundColor: "#FAE700",
        color: "#000",
    },
    paper2: {
        padding: theme.spacing(4),
        paddingRight: theme.spacing(10),
        paddingLeft: theme.spacing(10),
    },
    table: {
        width: 650,
        minWidth: 650,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
            timeout={{ appear: 100, enter: 500, exit: 500 }}
        />
    );
});

const ViewAttendance = (props) => {
    const classes = useStyles();
    const { data, open, handleViewClose } = props;

    return (
        <Fragment>
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleViewClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h5" className={classes.title}>
                                {`View Attendance - ${moment(data?.time_in).format('dddd, MMMM Do YYYY')}`}
                                <CalendarTodayIcon className={classes.icon} />
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleViewClose}
                                aria-label="close"
                            >
                                <CloseIcon className={classes.button} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <MuiDialogContent dividers>
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" className={classes.titleText}>
                                    ATTENDANCE DETAILS
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Box mt={2} className={classes.text}>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Mechanic :
                                            </Typography>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                className={classes.span}
                                            >
                                                {" "}
                                                {data?.mechanic?.firstname}
                                            </Typography>
                                            <p>Status: {data?.status}</p>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box mt={2} className={classes.text}>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Time In :
                                            </Typography>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                className={classes.span}
                                            >
                                                {" "}
                                                {data?.time_in ? moment(data?.time_in).format('hh:mm a') : ''}
                                            </Typography>
                                            <p>Remark: {data?.time_in_note}</p>
                                            {data?.time_in_file && <img src={data?.time_in_file} style={{ width: '200px', height: '200px' }} />}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box mt={2} className={classes.text}>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Time Out :
                                            </Typography>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                className={classes.span}
                                            >
                                                {" "}
                                                {data?.time_out ? moment(data?.time_out).format('hh:mm a') : ''}
                                            </Typography>
                                            <p>Remark: {data?.time_out_note}</p>
                                            {data?.time_out_file && <img src={data?.time_out_file} style={{ width: '100%', height: '200px' }} />}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box mt={2} className={classes.text}>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Worked hours :
                                            </Typography>
                                            <Typography
                                                paragraph={true}
                                                display={"inline"}
                                                className={classes.span}
                                            >
                                                {" "}
                                                {data?.working_hours}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                       <Box mt={2}>
                                            <p>Location: </p>
                                            {data?.latitude_time_in && data?.longitude_time_in && data?.latitude_time_out && data?.longitude_time_out && 
                                            <Map data={data} />}

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </MuiDialogContent>
                </Dialog>
            </div>
        </Fragment>
    )
}

export default ViewAttendance