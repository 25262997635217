import React, { Fragment, useEffect, useState, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import useModal from "../../hooks/use-modal";
import MaterialTable from "material-table";
import { tableIcons } from "./TableIcons.js";
import AlertDialog from "../alertDialog/AlertDialog";
import ViewDialog from "./viewDialog/ViewDialog";
import AssignDialog from "./assignDialog/AssignDialog";
import UpdateDialog from "./updateDialog/UpdateDialog";
import CarBuyingUpdateDialog from "./updateDialog/CarBuyingUpdateDialog";
import DispatchDialog from "./dispatchDialog/DispatchDialog";
import TagDialog from "./tagDialog/TagDialog";
import BackJobDialog from "./backJobDialog/BackJobDialog";
import ConfirmDialog from "./confirmDialog/ConfirmDialog";
import AddPaymentDialog from "./addPaymentDialog/AddPaymentDialog";
import DialogConfirmation from "./dialogConfirmation/DialogConfirmation";
import CompleteDialog from "./completeDialog/CompleteDialog";
import WarningMessage from "./WarningMessage";
import CancelDialog from "./cancelDialog/CancelDialog";
import PrintDialog from "./print/PrintDialog";
import DeleteDialog from "./deleteDialog/DeleteDialog";
import InspectionDataDialog from "./inspectionDataDialog/inspectionDataDialog";
import InspectionDataPdfDialog from "./inspectionDataDialog/pdf/InspectionDataPdfDialog.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { green, yellow, orange, red, purple } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import WorkIcon from "@material-ui/icons/Work";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Assessment from "@material-ui/icons/Assessment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import HistoryIcon from '@material-ui/icons/History';

// utils
import { numberConverter, numberWithCommas } from "../../utils/accounting";
import { paymentStatusTypes } from "../../utils/constant";

// Api config
import config from "../../utils/config";
import { Chip } from "@material-ui/core";
import ReopenDialog from "./reopenDialog/ReopenDialog";

const useStyles = makeStyles((theme) => ({
  request: {
    color: red[500],
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  requestTooltip: {
    fontSize: "0.75rem",
  },
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow["600"],
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  tag: {
    color: orange["A400"],
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  inspectionChecklist: {
    color: purple[500],
    "&:hover": {
      backgroundColor: "rgb(156 39 176 / 10%)",
    },
  },
  reopen: {
    color: orange[500],
    "&:hover": {
      backgroundColor: "rgb(156 39 176 / 10%)",
    },
  },
  ellipsis: {
    width: "200px",
    overflow: "hidden",
    position: "relative",
    display: "inline-block",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

let isInitial = true;

const BookingDataTable = (props) => {
  const { token, tableRef } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookFilter = useSelector((state) => state.bookFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [dataAddPayment, setDataAddPayment] = useState([]);
  const [openDialogConfirm, setDialogConfirm] = useState(false);
  const [warningDialog, setWarningDialog] = useState({
    isOpen: false,
    message: "",
  });

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  useEffect(() => {
    if (isInitial) {
      isInitial = false;
      return;
    }

    if (bookFilter && bookFilter.search) {
      tableRef.current.onChangePage({}, 0);
    }
    return () => {};
  }, [tableRef, bookFilter]);

  const userRole = localStorage.getItem("userRole");

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  // Update Dialog
  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // Car Buying Update Dialog
  const {
    modalState: modalStateUpdateCarBuying,
    modalData: modalDataUpdateCarBuying,
    openHandler: modalOpenUpdateCarBuying,
    closeHandler: modalCloseUpdateCarBuying,
  } = useModal();

  const handleUpdate = async (data) => {
    setIsLoading(true);
    let notManual = data.services;

    let transformedNotManualServices = notManual.map((item) => {
      const itemId = data?.servicesList?.find((i) => i.name === item.name);
      return {
        id: itemId?.id || null,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        total: item.price,
        type: "",
      };
    });

    let combinedServices = transformedNotManualServices; // this is the packages

    let manualServices = data.manualServices; // this is the services

    if (manualServices.length > 0) {
      let transformedManualServices = manualServices.map((item) => {
        return {
          id: item.id,
          name: item.name,
          remarks: item.remarks,
          quantity: item.quantity,
          price: item.price,
          total: item.totalPrice,
          type: "manual",
        };
      });
      combinedServices = [...combinedServices, ...transformedManualServices];
    }

    let update1 = {
      services: combinedServices,
      parts: data.parts || [],
      package_add_ons: data.package_add_ons,
      service_fee: numberConverter(data.service_fee),
      tires: data.tires || [],
      discount: data.discounts ? numberConverter(data.discounts) : 0.0,
      subTotal: numberConverter(data.subTotal),
      total: numberConverter(data.total),
      paymentMethod: data.payment_method,
    };

    let date = moment(data.ap_date).format("YYYY-MM-DD");
    let time = moment(data.ap_time).format("HH:mm:ss");
    let appointmentDateval = moment(`${date} ${time}`).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    let update2 = {
      name: data.name,
      lastname: data.lastname,
      email: data.email,
      contact_number: data.contact_number,
      service_location: data.service_location,
      make: data.make,
      model: data.model,
      year: data.year,
      mileage: data.mileage,
      transmission: data.transmission,
      fuel_type: data.fuel_type,
      liter_of_oil: data.liter_of_oil,
      engine_size: data.engine_size,
      color: data.color,
      parking_space: data.parking_space,
      property_type: data.property_type,
      reschedule_reason: data.reschedule_reason,
      plate_number: data.plate_number,
      appointmentDate: appointmentDateval,
      remarks: data.remarks,
      distance_in_km: numberConverter(data.distance_in_km),
      address: data.address,
      customer_type: data.customer_type,
      landmarks: data.landmarks,
      province: data.province,
      municipality: data.municipality,
      barangay: data.barangay,
      zip_code: data.zip_code,
      ads_source: data.ads_source,
      transaction_type: data.transaction_type,
      payment_amount: data.payment_amount,
      payment_reference_no: data.payment_reference_no,
      payment_type: data.payment_type,
      seller_type: data.seller_type,
      seller_name: data.seller_name,
      odometer: data.odometer,
    };

    try {
      // appointment services
      await axios.put(`${config.api}/appointments/${data.id}`, update1, {
        headers: { Authorization: `Bearer ${token}`},
      })

      // appointment details
      await axios.patch(`${config.api}/appointments/${data.id}`, update2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      setIsLoading(false);
      modalCloseUpdate();
      modalCloseUpdateCarBuying();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully Updated",
        typeAlert: "success",
      });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (error) {
      // Handle error for the first or second axios request
      const errorMessage = error.response?.data?.message || "Something went wrong please try again.";

      setIsLoading(false);
      modalCloseUpdate();
      modalCloseUpdateCarBuying();
      setAlert({
        ...alert,
        open: true,
        message: errorMessage,
        typeAlert: "error",
      });
    }
  }

  // Assign Dialog
  const {
    modalState: modalStateAssign,
    modalData: modalDataAssign,
    openHandler: modalOpenAssign,
    closeHandler: modalCloseAssign,
  } = useModal();

  const handleAssignUpdate = useCallback(
    (data) => {
      const mechanicsData = data.mechanics;
      const mechanics = [];

      mechanicsData.forEach(function (arrayItem) {
        let data = {
          mechanic_id: arrayItem.id,
          start_datetime: arrayItem.start_datetime,
          end_datetime: arrayItem.end_datetime,
        };
        mechanics.push(data);
      });

      const values = {
        id: data.id,
        status: 5,
        mechanics: mechanics,
        toComplete: data?.toComplete ? true : false,
      };

      setIsLoading(true);
      axios
        .patch(`${config.api}/appointments/${data.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          modalCloseAssign();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Updated",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseAssign();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseAssign, token, tableRef]
  );

  // Dispatch Dialog
  const {
    modalState: modalStateDispatch,
    modalData: modalDataDispatch,
    openHandler: modalOpenDispatch,
    closeHandler: modalCloseDispatch,
  } = useModal();

  const handleDispatch = useCallback(
    (data, id) => {
      setIsLoading(true);
      axios
        .patch(`${config.api}/appointments/${id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          modalCloseDispatch();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Dispatched",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseDispatch();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseDispatch, tableRef, token]
  );

  // Tag Dialog
  const {
    modalState: modalStateTag,
    modalData: modalDataTag,
    openHandler: modalOpenTag,
    closeHandler: modalCloseTag,
  } = useModal();

  // Backjob Tag Dialog
  const {
    modalState: modalStateBackJobTag,
    modalData: modalDataBackJobTag,
    openHandler: modalOpenBackJobTag,
    closeHandler: modalCloseBackJobTag,
  } = useModal();

  // Reopen Appointment Dialog
  const {
    modalState: modalStateReopen,
    modalData: modalDataReopenAppointment,
    openHandler: modalOpenReopenDialog,
    closeHandler: modalCloseReopenAppointment,
  } = useModal();

  const handleTag = useCallback(
    (data) => {
      let values = {
        id: data.id,
        created_by: data.created_by.id,
      };

      axios
        .patch(`${config.api}/appointments/${data.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          modalCloseTag();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Tagged",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          modalCloseTag();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseTag, tableRef, token]
  );

  const handleTagBackJob = useCallback(
    (data) => {
      let values = {
        id: data.id,
        is_backjob: data.is_backjob,
      };

      axios
        .patch(`${config.api}/appointments/${data.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          modalCloseBackJobTag();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Tagged",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          modalCloseBackJobTag();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseBackJobTag, tableRef, token]
  );

  // Confirm Dialog
  const {
    modalState: modalStateConfirm,
    modalData: modalDataConfirm,
    openHandler: modalOpenConfirm,
    closeHandler: modalCloseConfirm,
  } = useModal();

  const handleConfirm = useCallback(
    (id) => {
      setIsLoading(true);
      axios
        .patch(
          `${config.api}/appointments/${id}`,
          {
            status: 2,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          modalCloseConfirm();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Confirmed",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseConfirm(false);
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseConfirm, token, tableRef]
  );

  // Add dialog
  const {
    modalState: modalStateAdd,
    modalData: modalDataAdd,
    openHandler: modalOpenAdd,
    closeHandler: modalCloseAdd,
  } = useModal();

  const handleAddPayment = useCallback((data) => {
    setDataAddPayment(data);
    setDialogConfirm(true);
  }, []);

  // Dialog Confirmation - (Add Payment)
  const handleClickOpenDialog = useCallback(() => {
    setIsLoading(true);

    let payment_status = 3;
    if (dataAddPayment.outstandingBalance > 0) {
      payment_status = 2;
    }

    let data = {
      payment_status: payment_status,
      outstanding_balance: dataAddPayment.outstandingBalance,
      payment: {
        payment_mode: dataAddPayment.modeOfPayment
          ? dataAddPayment.modeOfPayment.replace(/ /g, "_")
          : "",
        payment_ref: dataAddPayment.paymentRefNo,
        payment_date: dataAddPayment.paymentDate,
        amount_paid: dataAddPayment.amountPaid ? dataAddPayment.amountPaid : "",
      },
      discount: dataAddPayment.discount,
    };
    axios
      .patch(`${config.api}/appointments/${dataAddPayment.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTimeout(() => {
          setIsLoading(false);
          setDataAddPayment([]);
          modalCloseAdd();
          setDialogConfirm(false);
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Added",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        }, 2500);
      })
      .catch((error) => {
        setDataAddPayment([]);
        setIsLoading(false);
        modalCloseAdd();
        setDialogConfirm(false);
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      });
  }, [
    alert,
    dataAddPayment.amountPaid,
    dataAddPayment.discount,
    dataAddPayment.id,
    dataAddPayment.modeOfPayment,
    dataAddPayment.outstandingBalance,
    dataAddPayment.paymentDate,
    dataAddPayment.paymentRefNo,
    modalCloseAdd,
    tableRef,
    token,
  ]);

  const handleCloseDialogConfirm = useCallback(() => {
    setDialogConfirm(false);
    setIsLoading(false);
  }, []);

  // Complete Dialog
  const {
    modalState: modalStateComplete,
    modalData: modalDataComplete,
    openHandler: modalOpenComplete,
    closeHandler: modalCloseComplete,
  } = useModal();

  const handleComplete = useCallback(
    (data, id) => {
      setIsLoading(true);
      axios
        .patch(`${config.api}/appointments/${id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          modalCloseComplete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Completed",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseComplete();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseComplete, tableRef, token]
  );

  // Warning Dialog for - (Complete Dialog)
  const handleOpenWarning = useCallback((message) => {
    setWarningDialog({
      isOpen: true,
      message: message,
    });
  }, []);

  const handleCloseWarning = useCallback(() => {
    setWarningDialog({
      isOpen: false,
      message: "",
    });
  }, []);

  // Cancel Dialog
  const {
    modalState: modalStateCancel,
    modalData: modalDataCancel,
    openHandler: modalOpenCancel,
    closeHandler: modalCloseCancel,
  } = useModal();

  const handleCancel = useCallback(
    (data) => {
      let values = {
        id: data.id,
        cancellation_type: data.cancellation_type,
        reason_for_cancellation:
          data.reason_for_cancellation === "others"
            ? data.other_inputs
            : data.reason_for_cancellation,
        status: 6,
      };

      axios
        .patch(`${config.api}/appointments/${data.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          modalCloseCancel();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Cancelled",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          modalCloseCancel();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseCancel, tableRef, token]
  );

  // Print Dialog
  const {
    modalState: modalStatePrint,
    modalData: modalDataPrint,
    openHandler: modalOpenPrint,
    closeHandler: modalClosePrint,
  } = useModal();

  // Delete Dialog
  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  // Inspection Data Dialog
  const {
    modalState: modalStateInspection,
    modalData: modalDataInspection,
    openHandler: modalOpenInspection,
    closeHandler: modalCloseInspection,
  } = useModal();

  // Inspection Data PDF Dialog
  const {
    modalState: modalStateInspectionDataPdf,
    modalData: modalDataInspectionDataPdf,
    openHandler: modalOpenInspectionDataPdf,
    closeHandler: modalCloseInspectionDataPdf,
  } = useModal();

  const pluckBodyMarkings = (data) => {
    const formData = new FormData();
    let currentIndex = 0;
    Object.keys(data).forEach((side) => {
      data[side].forEach((carPart) => {
        if (
          carPart.part &&
          carPart.body_markings &&
          carPart.image &&
          carPart.image instanceof File
        ) {
          formData.append(`car_side${currentIndex}`, side);
          formData.append(`car_part${currentIndex}`, carPart.part);
          formData.append(
            `body_markings${currentIndex}`,
            carPart.body_markings
          );
          formData.append(`image[]`, carPart.image);
          if (carPart.old_url) {
            formData.append(`old_url${currentIndex}`, carPart.old_url);
          }
          currentIndex++;
        }
      });
    });
    return formData;
  };

  const handleDelete = useCallback(
    (id) => {
      setIsLoading(true);
      axios
        .delete(`${config.api}/appointments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Deleted",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setIsLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseDelete, tableRef, token]
  );

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const handleReopenSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axios.patch(
        `${config.api}/appointments/reopen/${values.id}`,
        values,
        { headers: { Authorization: `Bearer ${token}`} }
      )

      setIsLoading(false);
      modalCloseReopenAppointment();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully Reopen",
        typeAlert: "success",
      });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (error) {
      setIsLoading(false);
      modalCloseReopenAppointment();
      setAlert({
        ...alert,
        open: true,
        message: "Something went wrong.",
        typeAlert: "error",
      });
    }
  }

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================View Dialog - (MODAL)================= */}
      <ViewDialog
        data={modalDataView}
        open={modalStateView}
        handleViewClose={modalCloseView}
      />
      {/* =================Update Dialog - (MODAL)================= */}
      <UpdateDialog
        token={token}
        data={modalDataUpdate}
        isLoading={isLoading}
        open={modalStateUpdate}
        handleUpdate={handleUpdate}
        handleUpdateClose={modalCloseUpdate}
      />
      {/* =================Update Car Buying Dialog - (MODAL)================= */}
      <CarBuyingUpdateDialog
        token={token}
        data={modalDataUpdateCarBuying}
        isLoading={isLoading}
        open={modalStateUpdateCarBuying}
        handleUpdate={handleUpdate}
        handleUpdateClose={modalCloseUpdateCarBuying}
      />

      {/* =================Assign Dialog - (MODAL)================= */}
      <AssignDialog
        token={token}
        handleAssignUpdate={handleAssignUpdate}
        data={modalDataAssign}
        isLoading={isLoading}
        open={modalStateAssign}
        handleAssignClose={modalCloseAssign}
      />

      {/* =================Dispatch Dialog - (MODAL)================= */}
      <DispatchDialog
        token={token}
        handleDispatch={handleDispatch}
        data={modalDataDispatch}
        isLoading={isLoading}
        open={modalStateDispatch}
        handleDispatchClose={modalCloseDispatch}
      />

      {/* =================Tag Dialog - (MODAL)================= */}
      <TagDialog
        token={token}
        handleTag={handleTag}
        data={modalDataTag}
        isLoading={isLoading}
        open={modalStateTag}
        handleTagClose={modalCloseTag}
      />

      {/* =================BackJob Dialog - (MODAL)================= */}
      <BackJobDialog
        token={token}
        handleTagBackJob={handleTagBackJob}
        data={modalDataBackJobTag}
        isLoading={isLoading}
        open={modalStateBackJobTag}
        handleTagClose={modalCloseBackJobTag}
      />

      {/* =================BackJob Dialog - (MODAL)================= */}
      <ReopenDialog
        handleReopenSubmit={handleReopenSubmit}
        data={modalDataReopenAppointment}
        isLoading={isLoading}
        open={modalStateReopen}
        handleReopenAppointmentClose={modalCloseReopenAppointment}
      />

      {/* =================Confirm Dialog - (MODAL)================= */}
      <ConfirmDialog
        data={modalDataConfirm}
        isLoading={isLoading}
        open={modalStateConfirm}
        handleConfirm={handleConfirm}
        handleConfirmClose={modalCloseConfirm}
      />

      {/* =================Add Payment Dialog - (MODAL)================= */}
      <AddPaymentDialog
        token={token}
        handleAddPayment={handleAddPayment}
        data={modalDataAdd}
        isLoading={isLoading}
        open={modalStateAdd}
        handleAddPaymentClose={modalCloseAdd}
      />

      {/* =================Dialog Confirmation for Add Payment Dialog- (MODAL)================= */}
      <DialogConfirmation
        openDialogConfirm={openDialogConfirm}
        handleClickOpenDialog={handleClickOpenDialog}
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        isLoading={isLoading}
      />

      {/* =================Complete Dialog- (MODAL)================= */}
      <CompleteDialog
        handleComplete={handleComplete}
        data={modalDataComplete}
        isLoading={isLoading}
        open={modalStateComplete}
        handleCompleteClose={modalCloseComplete}
      />

      {/* =================Warning Dialog (MODAL)================= */}
      <WarningMessage
        isOpen={warningDialog.isOpen}
        message={warningDialog.message}
        handleCloseWarning={handleCloseWarning}
      />

      {/* =================Cancel Dialog- (MODAL)================= */}
      <CancelDialog
        data={modalDataCancel}
        isLoading={isLoading}
        open={modalStateCancel}
        handleCancel={handleCancel}
        handleCancelClose={modalCloseCancel}
      />

      {/* =================Print Dialog- (MODAL)================= */}
      <PrintDialog
        token={token}
        data={modalDataPrint}
        open={modalStatePrint}
        handleClosePrint={modalClosePrint}
      />

      {/* =================Delete Dialog- (MODAL)================= */}
      <DeleteDialog
        data={modalDataDelete}
        isLoading={isLoading}
        open={modalStateDelete}
        handleDelete={handleDelete}
        handleDeleteClose={modalCloseDelete}
      />

      {/* =================Inspection data - (MODAL)================= */}
      <InspectionDataDialog
        token={token}
        data={modalDataInspection}
        isLoading={isLoading}
        open={modalStateInspection}
        handleInspectionClose={modalCloseInspection}
      />

      {/* =================Inspection Data PDF Dialog - (MODAL)================= */}
      <InspectionDataPdfDialog
        token={token}
        data={modalDataInspectionDataPdf}
        isLoading={isLoading}
        open={modalStateInspectionDataPdf}
        handleInspectionDataPdfClose={modalCloseInspectionDataPdf}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Bookings Table`}
        tableRef={tableRef}
        columns={[
          { title: "Billing Statement No.", field: "bs_number" },
          {
            title: "Appointment Date & Time",
            field: "appointment_date",
            render: (client) => {
              return (
                <div>
                  <p>
                    {moment(client.appointment_date).format("MM-DD-YY hh:mm A")}
                  </p>
                  {client.reschedule_reason && (
                    <Chip
                      size="small"
                      label="Rescheduled"
                      style={{
                        color: "#fff",
                        padding: "p-2",
                        backgroundColor: "#8064A2",
                      }}
                    />
                  )}
                  <p></p>
                </div>
              );
            },
          },
          {
            title: "Customer Name",
            field: "name",
            render: (client) => {
              let name;
              typeof client.name !== "string"
                ? (name = "")
                : (name = client.name + " " + client.lastname);
              return (
                <span style={{ textTransform: "capitalize" }}>{name}</span>
              );
            },
          },
          {
            title: "Vehicle",
            field: "make",
            render: (client) => {
              return (
                <span>{`${client.make} ${client.model} ${client.year}`}</span>
              );
            },
          },
          {
            title: "Type",
            field: "customer_type",
            render: (client) => (
              <>
                <span style={{ textTransform: "capitalize" }}>
                  {client.lead_source}
                </span>
                <span style={{ textTransform: "capitalize" }}>
                  {`${
                    client.transaction_type
                      ? ` | ${client.transaction_type}`
                      : ""
                  }`}
                </span>
              </>
            ),
          },
          { title: "Service Location", field: `service_location` },
          {
            title: "Mechanic/s",
            sorting: false,
            render: (client) => {
              let mechanics = client.appointment_mechanics.map((item) => {
                return (
                  <p style={{ margin: "2px" }} key={item.id}>
                    {item.mechanic.firstname} {item.mechanic.lastname}
                  </p>
                );
              });
              return <>{mechanics}</>;
            },
          },
          {
            title: "Sales Agent",
            sorting: false,
            render: (client) => {
              let name = client.created_by
                ? client.created_by?.name +
                  " " +
                  (client.created_by?.lastname
                    ? client.created_by?.lastname
                    : "")
                : "";
              return (
                <span style={{ textTransform: "capitalize" }}>{name}</span>
              );
            },
          },
          {
            title: "Invoice Amount",
            sorting: false,
            render: (client) => {
              const subTotal = parseFloat(client.sub_total);
              const serviceFee = parseFloat(client.service_fee);
              const discount = parseFloat(client.discount);
              let invoiceAmount = subTotal + serviceFee - discount;
              if (client.appointment_status_id === 6) {
                // compute total reservation/downpayment paid
                const totalPaymentsMade =
                  client?.payment?.length > 0
                    ? client.payment
                        .filter((p) => p.payment_type !== null) // Use lowercase `null` for the value check
                        .reduce(
                          (total, payment) =>
                            total + parseFloat(payment.amount_paid),
                          0
                        )
                    : 0;
                // Check if the appointment was dispatched
                const isDispatched = client.appointment_history.find(
                  (history) => history.appointment_status === "Dispatched"
                );

                // Add service fee if dispatched
                invoiceAmount =
                  totalPaymentsMade + (isDispatched ? serviceFee : 0);
              }
              const paymentInfo =
                paymentStatusTypes[client.payment_status.name];
              return (
                <div style={{ textAlign: "center" }}>
                  <p style={{ marginBottom: "0" }}>
                    ₱{numberWithCommas(invoiceAmount)}
                  </p>
                  <p style={{ marginTop: "0" }}>
                    <Chip
                      size="small"
                      label={paymentInfo.text}
                      style={{
                        color: "#fff",
                        padding: "p-2",
                        backgroundColor: paymentInfo.color,
                      }}
                    />
                  </p>
                </div>
              );
            },
          },
          {
            title: "Status",
            field: `appointment_status_id`,
            render: (client) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      color: "#161B25",
                      marginRight: "0.5rem",
                    }}
                  >
                    {client.status.name}
                  </span>
                </div>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/appointments?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (bookFilter && bookFilter.orderStatus !== "all") {
              url += `&status=${bookFilter.orderStatus}`;
            }
            if (bookFilter && bookFilter.paymentStatus !== "all") {
              url += `&payment_status=${bookFilter.paymentStatus}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.bookingDateFrom &&
              bookFilter.bookingDateTo
            ) {
              url += `&booking_date_from=${bookFilter.bookingDateFrom}&booking_date_to=${bookFilter.bookingDateTo}`;
            }

            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.appointmentDateFrom &&
              bookFilter.appointmentDateTo
            ) {
              url += `&appointment_date_from=${bookFilter.appointmentDateFrom}&appointment_date_to=${bookFilter.appointmentDateTo}`;
            }

            if (bookFilter && bookFilter.search && bookFilter.appointmentTime) {
              const time = moment(bookFilter.appointmentTime).format(
                "HH:mm:ss"
              );
              url += `&appointment_time=${time}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.serviceLocation.length
            ) {
              url += `&service_location=${bookFilter.serviceLocation}`;
            }
            if (
              bookFilter &&
              bookFilter.search &&
              bookFilter.transactionType.length
            ) {
              url += `&transaction_type=${bookFilter.transactionType}`;
            }
            if (query.orderBy && query.orderDirection) {
              url += `&sort=${query.orderDirection}&field=${query.orderBy.field}`;
            }
            if (bookFilter && bookFilter.search && bookFilter.mechanic) {
              url += `&mechanic_id=${bookFilter.mechanic}`;
            }
            if (bookFilter && bookFilter.search && bookFilter.salesAgent) {
              url += `&sales_agent_id=${bookFilter.salesAgent}`;
            }

            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              })
              .catch((err) => reject(err));
          })
        }
        actions={[
          {
            icon: "request",
            tooltip: "Request",
            onClick: (event, rowData) => {},
          },
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              if (rowData.appointment_services.length === 1) {
                const findCarBuying = rowData.appointment_services.find(
                  (appt) => appt.package_id === 25
                );
                if (findCarBuying) {
                  modalOpenUpdateCarBuying(rowData);
                } else {
                  modalOpenUpdate(rowData);
                }
              } else {
                modalOpenUpdate(rowData);
              }
            },
          },
          {
            icon: "assign",
            tooltip: "Assign",
            onClick: (event, rowData) => {
              if (!rowData.service_location) {
                setWarningDialog({
                  isOpen: true,
                  message:
                    "Service location is required before assigning mechanics.",
                });
              } else {
                modalOpenAssign(rowData);
              }
            },
          },
          {
            icon: "dispatch",
            tooltip: "Dispatch",
            onClick: (event, rowData) => {
              modalOpenDispatch(rowData);
            },
          },
          {
            icon: "tag",
            tooltip: "tag",
            onClick: (event, rowData) => {
              modalOpenTag(rowData);
            },
          },
          {
            icon: "tag backjob",
            tooltip: "Tag Backjob",
            onClick: (event, rowData) => {
              modalOpenBackJobTag(rowData);
            },
          },
          {
            icon: "confirm",
            tooltip: "Confirm",
            onClick: (event, rowData) => {
              modalOpenConfirm(rowData);
            },
          },
          {
            icon: "add",
            tooltip: "Add Payment",
            onClick: (event, rowData) => {
              modalOpenAdd(rowData);
            },
          },
          {
            icon: "complete",
            tooltip: "Complete",
            onClick: (event, rowData) => {
              if (rowData.status.id !== 1) {
                const hasNullEndDatetime = rowData.appointment_mechanics.some(
                  (apptMechanic) => apptMechanic.end_datetime === null
                );
                if (hasNullEndDatetime) {
                  modalOpenAssign({ ...rowData, toComplete: true });
                } else {
                  modalOpenComplete({ ...rowData, toComplete: true });
                }
              }
            },
          },
          {
            icon: "cancel",
            tooltip: "Cancel",
            onClick: (event, rowData) => {
              modalOpenCancel(rowData);
            },
          },
          {
            icon: "print",
            tooltip: "print",
            onClick: (event, rowData) => {
              modalOpenPrint(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
          {
            icon: "inspection data",
            tooltip: "Inspection data",
            onClick: (event, rowData) => {
              modalOpenInspection(rowData);
            },
          },
          {
            icon: "inspection data pdf",
            tooltip: "Inspection data pdf",
            onClick: (event, rowData) => {
              modalOpenInspectionDataPdf(rowData);
            },
          },
          {
            icon: "reopeon appointment",
            tooltip: "Reopeon Appointment",
            onClick: (event, rowData) => {
              modalOpenReopenDialog(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Request for Schedule=====================
            if (props.action.icon === "request") {
              if (!props.data.appointment_request) {
                return "";
              } else {
                return (
                  <Tooltip
                    title={
                      <div className={classes.requestTooltip}>
                        <div>Request for Schedule:</div>
                        <br />
                        <div>
                          I want to request for an earlier or a different
                          schedule
                        </div>
                        <br />
                        {props.data.appointment_request_date && (
                          <div>
                            <div>
                              Date:{" "}
                              {moment(
                                props.data.appointment_request_date
                              ).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              Time:{" "}
                              {moment(
                                props.data.appointment_request_date
                              ).format("hh:mm A")}
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  >
                    <IconButton
                      id="request"
                      aria-label="request"
                      className={classes.request}
                      size="small"
                    >
                      <ChatBubbleIcon />
                    </IconButton>
                  </Tooltip>
                );
              }
            }
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Assign=====================
            if (props.action.icon === "assign") {
              return (
                <Tooltip title="Assign">
                  <IconButton
                    aria-label="assign"
                    color="primary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 1 ||
                      props.data?.status?.id === 3 ||
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <AssignmentIndIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Dispatch=====================
            if (props.action.icon === "dispatch") {
              return (
                <Tooltip title="Dispatch">
                  <IconButton
                    aria-label="dispatch"
                    color="primary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 1 ||
                      props.data?.status?.id === 2 ||
                      props.data?.status?.id === 3 ||
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <AirportShuttleIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Tag=====================
            if (props.action.icon === "tag") {
              return (
                <Tooltip title="Tag">
                  <IconButton
                    aria-label="tag"
                    className={classes.tag}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <LocalOfferIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Tag=====================
            if (props.action.icon === "tag backjob") {
              return (
                <Tooltip title="Tag Back job">
                  <IconButton
                    aria-label="tag"
                    className={classes.tag}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 1 ||
                      props.data?.status?.id === 2 ||
                      props.data?.status?.id === 3 ||
                      props.data?.status?.id === 5 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <WorkIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Confirm=====================
            if (props.action.icon === "confirm") {
              return (
                <Tooltip title="Confirm">
                  <IconButton
                    aria-label="confirm"
                    color="primary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={props.data?.status?.id !== 1}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================ADD=====================
            if (props.action.icon === "add") {
              return (
                <Tooltip title="Add Payment">
                  <IconButton
                    aria-label="add payment"
                    className={classes.add}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6 ||
                      props.data.payment_status_id === 3 ||
                      props.data.payment_method === "payment_paypal"
                    }
                  >
                    <MonetizationOnIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Complete=====================
            if (props.action.icon === "complete") {
              return (
                <Tooltip title="Complete">
                  <IconButton
                    aria-label="complete"
                    className={classes.add}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 1 ||
                      props.data?.status?.id === 5 ||
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Cancel=====================
            if (props.action.icon === "cancel") {
              return (
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    color="secondary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                    disabled={
                      props.data?.status?.id === 4 ||
                      props.data?.status?.id === 6
                    }
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Print=====================
            if (props.action.icon === "print") {
              return (
                <Tooltip title="Print">
                  <IconButton
                    aria-label="print"
                    color="primary"
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              // if (props.data?.status?.id !== 4) {
              if (userRole === "super_admin") {
                return (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      size="small"
                      onClick={(event) => props.action.onClick(event, props.data)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                );
              }
              return null;
              // } else {
              //   return "";
              // }
            }
            // =====================Inspection data=====================
            if (props.action.icon === "inspection data") {
              if (props.data?.inspection?.length === 0 && props.data?.buying_inspection?.length === 0) return "";
              return (
                <Tooltip title="Inspection Data">
                  <IconButton
                    id="inspection data"
                    aria-label="inspection data"
                    size="small"
                    className={classes.inspectionChecklist}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <Assessment />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Inspection data pdf=====================
            if (props.action.icon === "inspection data pdf") {
              if (props.data?.inspection?.length === 0) return "";
              return (
                <Tooltip title="Inspection Data PDF">
                  <IconButton
                    id="inspection data pdf"
                    aria-label="inspection data pdf"
                    size="small"
                    className={classes.inspectionChecklist}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Reopen Appointment=====================
            if (props.action.icon !== "reopen appointment") return null;
            if (props.data.reopen_reason !== null) return null;
            if (props.data.appointment_status_id !== 4) return null;
            if (userRole !== "super_admin") return null;
            return (
              <Tooltip title="Reopen Appointment">
                <IconButton
                  id="reopen_appointment"
                  aria-label="reopen appointment"
                  size="small"
                  className={classes.reopen}
                  onClick={(event) => props.action.onClick(event, props.data)}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            );

          },
        }}
        options={{
          headerStyle: {
            width: "100%",
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            // display: "flex",
            justifyContent: "center",
            padding: "24px",
            width: "100%",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          sorting: true,
        }}
        onRowClick={(event, rowData) => {
          if (typeof event.target.outerText != "undefined") {
            modalOpenView(rowData);
          }
        }}
      />
    </Fragment>
  );
};

export default BookingDataTable;
